import React from 'react'

import logoBird from '../assets/images/logoBird.jpg';
import logoText from '../assets/images/logoText.png';

const BannerLanding = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major header">
                <img src={logoBird} />
                <img className="logo-text" src={logoText} />
            </header>
        </div>
    </section>
)

export default BannerLanding
