import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import BannerLanding from '../components/BannerLanding'

const NotFound = (props) => (
    <div>
        <Helmet>
            <title>Littlebirds Speech, Language and Literacy</title>
            <meta name="description" content="Page Not Found" />
        </Helmet>

        <BannerLanding />

        <div id="main" className="alt">
            <div className="inner">
                <section id="about">
                    <header className="major">
                        <h1>Page Not Found</h1>
                    </header>
                    <p>Sorry we can't find the page you're looking for.</p>
                </section>
            </div>
        </div>
    </div>
)

export default NotFound